/**
 * @generated SignedSource<<2c362fe4a56a69a8301880aef935f552>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GuildMetadata_guild$data = {
  readonly description: string | null | undefined;
  readonly name: string;
  readonly slugId: string;
  readonly updatedAt: String | null | undefined;
  readonly " $fragmentType": "GuildMetadata_guild";
};
export type GuildMetadata_guild$key = {
  readonly " $data"?: GuildMetadata_guild$data;
  readonly " $fragmentSpreads": FragmentRefs<"GuildMetadata_guild">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GuildMetadata_guild",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slugId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "Guild",
  "abstractKey": null
};

(node as any).hash = "8ae4b3f3745b80369d3f7b6ae6152bb3";

export default node;
