/**
 * @generated SignedSource<<02b061edc9d4f7f587257acdee048633>>
 * @relayHash 02bd6a5909dc617c5d0895ae8f61b507
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 45f2bd3997e6efa6ce3006420934933e888d8f8fa6576816cc37bbb8d8d86096

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GuildTypes = "GUILD" | "NETWORK" | "%future added value";
export type GuildNavigatorQuery$variables = {
  id: string;
};
export type GuildNavigatorQuery$data = {
  readonly nodeBySlugId: {
    readonly __typename: "Guild";
    readonly donationCurrency: string | null | undefined;
    readonly id: string;
    readonly minimumDonationAmount: number | null | undefined;
    readonly myMembership: {
      readonly id: string;
    } | null | undefined;
    readonly slugId: string;
    readonly totalEvents: {
      readonly edges: ReadonlyArray<{
        readonly cursor: String;
      }>;
    };
    readonly totalGuilds: {
      readonly edges: ReadonlyArray<{
        readonly cursor: String;
      }>;
    };
    readonly totalNetworks: {
      readonly edges: ReadonlyArray<{
        readonly cursor: String;
      }>;
    };
    readonly totalPresentations: {
      readonly edges: ReadonlyArray<{
        readonly cursor: String;
      }>;
    };
    readonly type: GuildTypes | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"GuildCardNew_guild" | "GuildJoinConfirmationContent_guild" | "GuildJoinConfirmation_guild" | "GuildMetadata_guild">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type GuildNavigatorQuery = {
  response: GuildNavigatorQuery$data;
  variables: GuildNavigatorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slugId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = [
  (v3/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimumDonationAmount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "donationCurrency",
  "storageKey": null
},
v9 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v10 = [
  (v9/*: any*/),
  {
    "kind": "Literal",
    "name": "membershipStatus",
    "value": "ACCEPTED"
  }
],
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cursor",
    "storageKey": null
  }
],
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GuildsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": (v11/*: any*/),
    "storageKey": null
  }
],
v13 = {
  "alias": "totalNetworks",
  "args": (v10/*: any*/),
  "concreteType": "GuildsConnection",
  "kind": "LinkedField",
  "name": "rankedNetworks",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": "rankedNetworks(first:1,membershipStatus:\"ACCEPTED\")"
},
v14 = {
  "alias": "totalGuilds",
  "args": (v10/*: any*/),
  "concreteType": "GuildsConnection",
  "kind": "LinkedField",
  "name": "rankedNetworkGuilds",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": "rankedNetworkGuilds(first:1,membershipStatus:\"ACCEPTED\")"
},
v15 = [
  (v9/*: any*/)
],
v16 = {
  "alias": "totalEvents",
  "args": (v15/*: any*/),
  "concreteType": "EventsConnection",
  "kind": "LinkedField",
  "name": "events",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": (v11/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "events(first:1)"
},
v17 = {
  "alias": "totalPresentations",
  "args": (v15/*: any*/),
  "concreteType": "PresentationsConnection",
  "kind": "LinkedField",
  "name": "presentations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PresentationsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": (v11/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "presentations(first:1)"
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v19 = [
  (v18/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contentType",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GuildNavigatorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodeBySlugId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GuildMembership",
                "kind": "LinkedField",
                "name": "myMembership",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GuildCardNew_guild"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GuildMetadata_guild"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GuildJoinConfirmation_guild"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GuildJoinConfirmationContent_guild"
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "type": "Guild",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GuildNavigatorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodeBySlugId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GuildMembership",
                "kind": "LinkedField",
                "name": "myMembership",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "membershipLevel",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UsersConnection",
                "kind": "LinkedField",
                "name": "networkMembers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "primaryPhoto",
                "plural": false,
                "selections": (v19/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "backgroundPhoto",
                "plural": false,
                "selections": (v19/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "type": "Guild",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "45f2bd3997e6efa6ce3006420934933e888d8f8fa6576816cc37bbb8d8d86096",
    "metadata": {},
    "name": "GuildNavigatorQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "713e233203400565b4ed472eb2014c4f";

export default node;
